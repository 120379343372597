@import "../../styles/colors.css";

.lazyImage {
  background-color: var(--grey-20) !important;
}

.lazyImage.silver {
  background-color: var(--grey-40) !important;
}

.lazyImage.darkGrey {
  background-color: var(--grey-70) !important;
}
