@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";

.subTotalContainer {
  composes: leavesden2 from "~styles/typography/styles.css";
  background-color: var(--grey-10);
  box-shadow: 0 -2px 3px 0 rgba(153, 153, 153, 0.2);
  box-sizing: border-box;
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  width: 100%;
  height: 50px;
}