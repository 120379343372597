@import "../../../styles/layout.css";
@import "../../../styles/colors";
@import "../../../styles/viewports.css";

.container {
  visibility: visible;
  padding-bottom: var(--navigation-padding);
}

.containerList {
  border-bottom: var(--navigation-padding) solid var(--white);
}

.containerList > li:first-child > ul li:first-child {
  padding-bottom: 0;
}

.container__hidden {
  visibility: hidden;
  position: absolute;
}

@media (--large-viewport) {
  .container {
    display: none;
  }
}
