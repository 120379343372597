/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";

.container {
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
}

.header {
  composes: london2 from "~styles/typography/styles.css";
  text-transform: uppercase;
  padding: 0 44px 16px 0;
  width: 100%;
  color: var(--grey-90);
  box-sizing: border-box;
}

.form {
  border-top: 1px solid var(--grey-40);
  display: flex;
  flex: auto;
  flex-direction: column;
}

.field {
  padding-top: 16px;
}

.features {
  composes: leavesden2 from "~styles/typography/styles.css";
  background-color: var(--grey-10);
  padding: 16px;
}

.providers {
  margin-bottom: -8px;
}

.propositions + .providers {
  margin-top: 12px;
}

.provider {
  border-radius: 2px;
  width: 32px;
  height: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
}

.provider:last-child {
  margin-right: 0;
}

.provider img {
  background-color: var(--grey-40);
  display: block;
  height: 20px;
  margin: 0 auto;
  vertical-align: bottom;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.currency {
  composes: leavesden2 from "~styles/typography/styles.css";
  color: var(--black);
  padding-left: 5px;
}

.label {
  composes: london3 from "~styles/typography/styles.css";
  text-transform: uppercase;
  color: var(--grey-90);
}

.label::after {
  content: ":";
}

.label__disabled {
  opacity: 0.5;
}

.select {
  appearance: none;
  composes: leavesden2 from "~styles/typography/styles.css";
  border-radius: 0;
  width: 100%;
  height: 44px;
  padding: 0 44px 0 16px;
  margin-top: 10px;
  background-color: var(--white);
  background-image: svg-inline(sortdown);
  background-position: right 16px center;
  background-repeat: no-repeat;
  border: 1px solid rgba(153, 153, 153, 0.8);
  text-overflow: ellipsis;
  overflow: hidden;
}

.select:disabled {
  opacity: 0.5;
}

.select::-ms-expand {
  display: none;
}

.select:focus::-ms-value {
  background: none;
  color: var(--black);
}

.actions {
  flex: auto;
  display: flex;
  align-items: flex-end;
}

.submitButton {
  composes: london2-button from "~styles/typography/styles.css";
  text-transform: uppercase;
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 0;
  background-color: var(--black);
  color: var(--white);
  margin-top: 30px;
}

.closeButton {
  position: absolute;
  top: 2px;
  right: 16px;
  width: 44px;
  height: 44px;
  border: none;
  background-color: transparent;
  margin-top: 2px;
}

.closeIcon {
  --icon: svg-inline(close);
  
  composes: darkIcon from "~styles/icons/styles.css";
  mask-size: 20px;
}

.error,
.warning {
  position: relative;
  margin: 16px -16px -16px;  
  padding: 12px 32px 12px 60px;
  color: var(--black);  
  composes: leavesden3 from "~styles/typography/styles.css";
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 30px 14px;
}

.error {
  background-color: var(--very-light-purple);
  background-image: svg-inline(notification-red);
}

.warning {
  background-color: var(--light-amber);
  background-image: svg-inline(notification);

}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .field {
    padding-bottom: 16px;
  }
}

@media (--medium-viewport) {
  .header {
    composes: leavesden1 from "~styles/typography/styles.css";
    letter-spacing: 2.5px;
  }

  .closeButton {
    position: absolute;
    top: 12px;
    right: 32px;
  }

  .error, .warning {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -30px;
  }
}

@supports (display: grid) {
  .providers {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32px);
    grid-gap: 8px;
    margin-bottom: 0;
  }

  .provider {
    margin-right: 0;
    margin-bottom: 0;
  }
}
