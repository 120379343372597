/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";
@import "../../../../styles/viewports.css";

:root {
  --circle-image-size: 40px;
}

.menuItem {
  width: 100%;
  height: 60px;
  border-bottom: var(--secondary-nav-divider);
  background-color: var(--white);
  box-sizing: border-box;
  display: block;
}

.menuItem:first-child {
  margin-top: -16px;
}

.menuItem:last-child {
  margin-bottom: -16px;
  border-bottom: 0;
}

.button {
  display: flex;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.circleImage {
  background-position: 0 0;
  background-size: var(--circle-image-size);
  width: var(--circle-image-size);
  height: var(--circle-image-size);
  margin-right: 15px;
  border-radius: 50%;
  align-self: center;
  flex: none;
}

.label {
  composes: asosFocus from "~styles/accessibility.css";
  font-size: var(--standard-font-size);
  color: var(--black);
  overflow: hidden;
  align-self: center;
  line-height: initial;
}

.label__sale {
  color: var(--red);
}

.label__bold {
  font-weight: 800;
}

.menuItem:hover span {
  color: var(--blue);
}

.label:focus {
  color: var(--blue);
}

@media (--medium-viewport) {
  .label {
    font-size: var(--standard-font-size);
  }
}
