/* REQUIRES SCREENSHOT STILL */
@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";

:root {
  --description-height: 70px;
  --thumbnail-aspect-ratio: 50%;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
}

.card {
  position: relative;
  flex-basis: calc(25% - 15px);
  height: auto;
  margin-right: 20px;
  margin-bottom: 16px;
  text-decoration: none;
}

.card:nth-child(4n) {
  margin-right: 0;
}

.thumbnailWrapper {
  position: relative;
}

.thumbnailCover {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.thumbnailCover .dark {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--asos-white-rgb), 0),
    rgba(248, 248, 248, 0.75)
  );
}

.image {
  background-position: 0 0;
  background-size: contain;
  width: 100%;
  padding-top: var(--thumbnail-aspect-ratio);
}

.cardTitle {
  composes: london1 from "~styles/typography/styles.css";
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 16px;
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(var(--asos-black-rgb), 0.1);
}

.cardTitle.light {
  color: var(--white);
}

.cardTitle.dark {
  color: var(--black);
}

.cardDescription {
  composes: leavesden3 from "~styles/typography/styles.css";
  composes: asosFocus from "~styles/accessibility.css";
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 70px;
  background: var(--white);
  color: var(--black);
  padding: 10px 16px 16px;
  max-height: var(--description-height);
  overflow: hidden;
}

.cardDescription:hover {
  color: var(--blue);
}

.noTitle {
  composes: srOnly from "~styles/accessibility.css";
}
