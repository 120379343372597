@import "../../../styles/colors.css";

.container {
  list-style: none;
  color: var(--white);
  box-sizing: border-box;
  width: 50px;
  padding: 0;
  display: inline-flex;
}

.container__hidden {
  display: none;
}

@media screen and (max-width: 365px) {
  .container.myAccountWidget {
    display: none;
  }
}
