/**
 * This file should only ever be referenced using:
 *
 *   composes: <style> from "~styles/icons/styles.css";
 *
 * Using `@import` will result in duplicate CSS in our bundles.
 */

@import "../colors.css";
@import "./variables.css";

.lightIcon,
.darkIcon,
.blackIcon,
.greyIcon {
  mask-image: var(--icon);
  height: var(--base-icon-size);
  width: var(--base-icon-size);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.lightIcon {
  background-color: var(--white);
}

.darkIcon {
  background-color: var(--black);
}

.blackIcon {
  background-color: var(--full-black);
}

.greyIcon {
  background-color: var(--grey-90);
}
