/* stylelint-disable property-disallowed-list */

/**
 * This file should only ever be referenced using:
 *
 *     composes: <style> from "~styles/typography/styles.css";
 *
 * Using `@import` will result in duplicate CSS in our bundles.
 */

@import "./variables.css";
@import "../viewports.css";

.london1 {
  composes: london1 from global;
}

.london2 {
  composes: london2 from global;
}

.london2-button {
  composes: london2-button from global;
}

.london3 {
  composes: london3 from global;
}

.london3-button {
  composes: london3-button from global;
}

.london4 {
  composes: london4 from global;
}

.london5 {
  composes: london5 from global;
}

.leavesden1 {
  composes: leavesden1 from global;
}

.leavesden2 {
  composes: leavesden2 from global;
}

.leavesden3 {
  composes: leavesden3 from global;
}

.leavesden4 {
  composes: leavesden4 from global;
}

.asosWorld2 {
  font-family: futura-pt, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.08px;
  text-transform: uppercase; 

  @media (--smallish-viewport) {
    font-size: 48px;
    line-height: 44px;
    letter-spacing: -1.44px;
  }
}

.asosWorld4 {
  font-family: futura-pt, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.72px;
  text-transform: uppercase; 

  @media (--smallish-viewport) {
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
}

.asosWorld5 {
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.72px;
  text-transform: uppercase; 

  @media (--smallish-viewport) {
    font-size: 24px;
    line-height: 24px;
  }
}

.h5Text {
  /* 
    basically london 4  
  */

  font-size: var(--smallest-font-size);
  font-family: var(--primary-font);
  letter-spacing: 1.7px;
  text-transform: uppercase;
  font-weight: 900;
}

.h4Text {
  /* 
    basically london3  

    font-size: 14px
    letter-spacing: 2px
  */

  font-size: var(--small-font-size);
  letter-spacing: 2px;
  font-weight: 900;
  font-family: var(--primary-font);
  text-transform: uppercase;
}

.h3Text {
  /* 
    basically london 2

    font-size 16px 
    letter-spacing 2px
    font-weight: 900
  */

  font-size: var(--standard-font-size);
  letter-spacing: 2px;
  font-weight: 900;
  font-family: var(--primary-font);
  text-transform: uppercase;
}

.bodyText {
  /* 
    basically this is leavesden3 as it is

    font-family: futura
    font-size: 14px 
    letter-spacing: 0.4px;
  */

  font-size: var(--small-font-size);
  font-family: var(--primary-font);
  letter-spacing: 0.4px;
}

.link {
  line-height: 2.14;
  display: inline-block;
  text-decoration: none;
}
