@import "../../../../styles/colors.css";
@import "../../../../styles/borders.css";

.container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}

.gridItem {
  width: 50%;
  padding-bottom: 8%;
  padding-left: 6px;
  box-sizing: border-box;
  text-align: center;
  max-height: 164px;
}

.gridItem:nth-child(2n) {
  padding-left: 0;
  padding-right: 4px;
}

.itemLabel {
  composes: leavesden3 from "~styles/typography/styles.css";
  composes: link from "~styles/typography/styles.css";
  height: 14px;
  margin: 8px 0;
  color: var(--grey-90);
}

.gridItem__sale span {
  color: var(--red);
}

.gridItemImageBorder {
  padding: 3px;
  border: var(--navigation-border);
}

.gridItemImage {
  background-position: 0 0;
  background-size: cover;
  padding-bottom: 100%;
}

.gridItemImageBorder,
.gridItemImage {
  border-radius: 50%;
  display: block;
  max-width: 86px;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0 auto;
}

.gridItem:hover span {
  color: var(--blue);
}

.gridItem:hover .gridItemImageBorder {
  border: var(--blue-border);
}
