/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */
@import "../../../../../styles/colors.css";
@import "../../../../../styles/viewports.css";

.container {
  margin-bottom: 16px;
}

.container:last-child {
  margin-bottom: 0;
}

.carouselLink {
  composes: h4Text from "~styles/typography/styles.css";
  composes: resetButton from "~styles/button.css";
  height: 78px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: inherit;
  background-size: 234px 100%;
  background-position: 0 0;
}

.carouselLink:hover {
  opacity: 0.7;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  width: 140px;
  color: var(--black);
  padding: 20px 0 20px 12px;
  box-sizing: border-box;
}

.label__light {
  color: var(--white);
}

.label__sale {
  color: var(--red);
}

.label > span {
  width: 100%;
  white-space: pre-line;
}

.subtitle {
  font-weight: 200;
}

.noTitle {
  composes: srOnly from "~styles/accessibility.css";
}

@media (--smallish-viewport) {
  .carouselLink {
    composes: h3Text from "~styles/typography/styles.css";
    height: 96px;
    background-size: 288px 100%;
  }

  .label {
    width: 180px;
  }
}
