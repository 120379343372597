@import "../../styles/colors.css";
@import "../../styles/viewports.css";

.container {
  height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.button {
  composes: asosFocus from "~styles/accessibility.css";
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  height: 30px;
  text-align: left;
  display: flex;
  align-items: center;
}

.locationText {
  composes: bodyText from "~styles/typography/styles.css";
}

.changeLabel {
  composes: h4Text from "~styles/typography/styles.css";
}

.button:hover {
  cursor: pointer;
}

.button > * {
  color: var(--grey-40);
}

.button:hover > * {
  color: var(--blue);
}

.locationText,
.changeLabel {
  color: var(--grey-90);
}

.button:hover .locationText,
.button:hover .changeLabel {
  color: var(--dark-blue);
}

.withLabels .button:focus {
  padding: 0 4px;
  margin-left: -4px;
}

.container:not(.withLabels) > * {
  padding: 0 17px;
}

.withLabels .button > * + * {
  margin-left: 7px
}

.pipe::before {
  content: "|";
  margin-right: 5px;
}


@media (--medium-viewport) {
  .container {
    height: 40px;
  }
}

@media (--large-viewport) {
  .container {
    height: auto;
    display: inline-block;
  }

  .withLabels .container {
    margin-left: 16px;
  }
}
