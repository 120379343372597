@import "../../styles/zindex.css";

.stickyHeader :global(.headroom--unfixed) {
  position: relative;
}

.stickyHeader :global(.headroom) {
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--headroom-z-index);
}

.stickyHeader :global(.headroom--scrolled) {
  top: -112px;
  left: 0;
  right: 0;
  position: fixed;
}

.stickyHeader :global(.headroom--pinned),
.stickyHeader__sidePanelOpen :global(.headroom),
:global(.chrome-hide-overflow) .stickyHeader :global(.headroom--unpinned) {
  top: 0 !important;
  position: fixed;
}
