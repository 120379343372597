@import "../../styles/colors.css";
@import "../../styles/viewports.css";

.iconBar {
  border-top: 1px solid var(--grey-20);
  width: 100%;
  background: var(--white);
  text-align: center;
}

.greeterWrapper {
  background-color: var(--grey-10);
  padding: 17px 0;
  border-bottom: 1px solid var(--grey-40);
}

.pageFooter {
  display: none;
}

@media (--medium-viewport) {
  .greeterWrapper {
    display: none;
  }

  .pageFooter {
    display: block;
  }
}
