@import "../../../../styles/colors.css";
@import "../../../../styles/viewports.css";

.container {
  margin-bottom: 16px;
  display: block;
}

.container:last-child {
  margin-bottom: 0;
}

.menuItem {
  composes: london2-button from "~styles/typography/styles.css";
  composes: resetButton from "~styles/button.css";
  height: 78px;
  width: 100%;
  box-sizing: border-box;
  background-size: 234px 100%;
  background-position: 0 0;
}

.menuItem:hover {
  opacity: 0.7;
}

.label {
  text-align: left;
  width: 140px;
  color: var(--black);
  padding-left: 12px;
  box-sizing: border-box;
}

.label__light {
  color: var(--white);
}

.label__sale {
  color: var(--red);
}

.label > span {
  width: 100%;
  white-space: pre-line;
  vertical-align: middle;
  display: inline-block;
}

.subtitle {
  composes: leavesden2 from "~styles/typography/styles.css";
}

.noTitle {
  composes: srOnly from "~styles/accessibility.css";
}

@media (--smallish-viewport) {
  .menuItem {
    composes: h3Text from "~styles/typography/styles.css";
    height: 96px;
    background-size: 288px 100%;
  }

  .label {
    width: 180px;
  }
}
