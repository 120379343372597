/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../../styles/borders.css";
@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";
@import "../../../../styles/viewports.css";

:root {
  --inspiration-image-width: 100%;
  --inspiration-image-height: 100%;
}

.card {
  display: inline-block;
  width: 234px;
  margin-bottom: 16px;
  font-size: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  text-decoration: none;
}

.card:last-child {
  margin: 0;
}

.card:focus {
  outline: none;
  border: var(--blue-border);
}

.wrapper {
  height: 115px;
  position: relative;
}

.image {
  background-position: 0 0;
  background-size: var(--inspiration-image-width)
    var(--inspiration-image-height);
  width: var(--inspiration-image-width);
  height: var(--inspiration-image-height);
}

.title {
  font-size: var(--large-font-size);
  letter-spacing: 2.5px;
  font-weight: 900;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  position: absolute;
  bottom: 14px;
  left: 14px;
}

.description {
  font-size: var(--standard-font-size);
  line-height: 24px;
  letter-spacing: 0.4px;
  height: 70px;
  padding: 14px;
  box-sizing: border-box;
  background-color: var(--white);
  color: var(--black);
}

.dark {
  color: var(--black);
}

.light {
  color: var(--white);
}

@media (--smallish-viewport) {
  .card {
    width: 288px;
  }

  .wrapper {
    height: 141px;
  }
}

.noTitle {
  composes: srOnly from "~styles/accessibility.css";
}