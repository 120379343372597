/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/borders.css";
@import "../../styles/layout.css";
@import "../../styles/viewports.css";

:root {
  --miscbar--height: 30px;
}

.container {
  display: none;
}

.link {
  composes: asosFocus from "~styles/accessibility.css"
}

@media (--large-viewport) {
  .container {
    composes: bodyText from "~styles/typography/styles.css";
    color: var(--grey-90);
    width: 100%;
    background-color: var(--grey-10);
    display: block;
  }

  .hidden {
    display: none;
  }

  .body {
    height: var(--miscbar--height);
    margin: 0 auto;
    max-width: var(--large-screen-max-content-width);
    box-sizing: border-box;
    padding: 0 var(--large-screen-side-padding);
  }

  .navigation {
    padding: 0;
    margin: 0;
    list-style: none;
    float: right;
    height: 100%;
  }

  .navigation li {
    display: block;
    float: left;
    height: 100%;
    border-left: var(--navigation-border);
  }

  .navigation li:last-of-type {
    border-right: var(--navigation-border);
  }

  .navigation a {
    display: block;
    color: var(--grey-90);
    padding: 0 19px;
    line-height: var(--miscbar--height);
    text-decoration: none;
  }

  .navigation a:hover,
  .navigation a:focus {
    color: var(--blue);
  }
}
