@import "../../../styles/colors.css";
@import "../../../styles/zindex.css";

.infoBannerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--info-banner-z-index);
}

.infoBanner {
  position: relative;
  background: var(--light-amber)
}

/* ANIMATIONS */

@keyframes bounce {
  25% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes show {
  100% {
    transform: translateY(0px);
  }
}

@keyframes hide {
  100% {
    transform: translateY(100%);
  }
}

.bounce {
  transition: 0.2s;
  transform: translateY(0px);
  transition-property: transform;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

.show {
  transform: translateY(100%);
  animation-name: show;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.hide {
  animation-name: hide;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.forceHide {
  animation-name: hide;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
