/* stylelint-disable property-disallowed-list */

/*  
    efforts to localise all typographic styles to a 
    single location (ab#829689) are not relevant to icon font sizing 
*/

.button {
  composes: asosFocus from "~styles/accessibility.css";
  background-color: transparent;
  border: none;
  color: currentcolor;
  font-size: 0;
  line-height: 0;
}
