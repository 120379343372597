@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";

.deliveryProposition {
  align-items: center;
  align-self: flex-end;
  color: var(--black);
  justify-content: center;
  padding: 8px;
  text-align: center;
  width: 100%;
  border-top: 1px solid var(--grey-40);
  box-sizing: border-box;
  height: 60px;
  background-color: var(--grey-10);
}

.title {
  display: block;
  composes: leavesden2 from "~styles/typography/styles.css";
}

.description {
  composes: leavesden4 from "~styles/typography/styles.css";
}

.descriptionLink {
  color: inherit;
}

.descriptionLink:hover, .descriptionLink:active {
  color: var(--blue);
}
