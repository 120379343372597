@import "../../styles/colors.css";
@import "../../styles/typography/variables.css";

.container {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  padding-left: 1em;
}

.welcome {
  composes: london2 from "~styles/typography/styles.css";
  color: var(--black);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 1em;
}

.link {
  composes: asosFocus from "~styles/accessibility.css";
  composes: leavesden2 from "~styles/typography/styles.css";
  color: var(--grey-90);
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline;
  white-space: nowrap;
  text-decoration: underline;
  padding: 0 5px;
  margin: 0 -5px;
}

.link:hover {
  color: var(--blue);
}

.links {
  display: flex;
  align-items: center;
}

.pipe::before {
  content: "|";
  display: block;
  padding: 0 0.5em;
  color: var(--grey-90);
}
