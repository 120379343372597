/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";
@import "../../styles/viewports.css";

.container {
  padding: 0;
  margin: 0;
  align-self: flex-end;
  display: flex;

  /* IE 9 hack */
  float: right \9;
}

.widgetButton {
  position: relative;
  padding: 12px 15px;
  font-family: inherit;
  display: block;
  cursor: pointer;
  max-height: 50px;
  line-height: 50px;
  box-sizing: border-box;
}

.widgetMyAccountLoggedOut {
  --icon: svg-inline(accountUnfilled);

  composes: lightIcon from "~styles/icons/styles.css";
}

.widgetSavedItemsUnfilled {
  --icon: svg-inline(savedItemsUnfilled);

  composes: lightIcon from "~styles/icons/styles.css";

}

.widgetBagUnfilled {
  --icon: svg-inline(bagUnfilled);

  composes: lightIcon from "~styles/icons/styles.css";
}

.widgetSearch {
  --icon: svg-inline(search);

  composes: lightIcon from "~styles/icons/styles.css";
}

@media (--medium-viewport) {
  .widgetButton {
    max-height: 60px;
    line-height: 60px;
  }
}

.noOfBagItems {
  top: 7%;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 900;
  position: absolute;
  color: var(--black);
  text-align: center;
}

:global(.bounce-enter.bounce-enter-active) {
  animation-name: bounce-minibag;
  animation-duration: 1s;
}

@keyframes bounce-minibag {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-8px);
  }

  60% {
    transform: translateY(-4px);
  }
}
