@import "../../../../styles/colors.css";
@import "../../../../styles/layout.css";

.grid {
  display: grid;
  grid-auto-flow: column;
  margin: calc(var(--navigation-padding) * -1) calc(var(--navigation-padding) * -1) 0;
}

.contentContainer {
  width: 164px;
  padding: var(--navigation-padding) 6px 0 var(--navigation-padding);
  box-sizing: border-box;

  @media (--smallish-viewport) {
    width: 214px;
  }
}

.narrowContentContainer {
  width: 148px;

  @media (--smallish-viewport) {
    width: 214px;
  }
}

.removeWhiteSpace {
  margin-bottom: calc(var(--navigation-padding) * -1);
}

.contentContainerPadding {
  padding-bottom: var(--navigation-padding);
}

.imageContainer {
  background: url(./assets/background.jpg);
  background-size: cover;
  width: 102px;

  @media (--smallish-viewport) {
    width: 106px;
  }
}

.wideImageContainer {
  width: 118px;

  @media (--smallish-viewport) {
    width: 106px;
  }
}

.linksContainer {
  margin-top: 19px;
}

.logo {
  width: 104px;

  @media (--smallish-viewport) {
    width: 121px;
  }
}

.padlock {
  position: absolute;
  top: var(--navigation-padding);
  right: var(--navigation-padding);
}

.highCountTitle {
  composes: asosWorld4 from "~styles/typography/styles.css";
  margin: 16px 0 10px;

  @media (--smallish-viewport) {
    margin: 12px 0;
  }
}

.itemCount {
  composes: asosWorld2 from "~styles/typography/styles.css";
  display: block;
}

.lowCountTitle {
  composes: asosWorld5 from "~styles/typography/styles.css";
  white-space: pre-wrap;
  margin: 6px 0 8px;
}

.content {
  composes: leavesden3 from "~styles/typography/styles.css";
}

.link {
  composes: leavesden3 from "~styles/typography/styles.css";
  text-align: center;
  display: block;
  margin-top: 14px;
  color: var(--black);
}