@import "../../../../styles/colors.css";

:root {
  --button-spacing: 6px;
}

.bagLinks {
  align-items: center;
  align-self: flex-end;
  color: var(--black);
  justify-content: center;
  padding: 12px 16px;
  text-align: center;
  width: 100%;
  border-top: 1px solid var(--grey-40);
  box-sizing: border-box;
  height: 70px;
}

.mvtBagLinks {
  align-items: center;
  align-self: flex-end;
  color: var(--black);
  justify-content: center;
  padding: 12px 16px 4px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.mvtBagLinks button {
    height: 35px;
}

.buttonContainer {
  box-sizing: border-box;
  float: left;
  padding: 0;
  width: 50%;
}

.buttonContainer:first-child {
  padding-right: var(--button-spacing);
}

.buttonContainer:last-child {
  padding-left: var(--button-spacing);
}
