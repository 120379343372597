@import "../../styles/colors.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";

.button {
  composes: london3-button from "~styles/typography/styles.css";
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border: 2px solid var(--grey-40);
  padding: 3px 12px;
  position: relative;
  width: 100%;
  height: 44px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.button__isPrimary {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}

.button__isPrimary:hover {
  background-color: var(--full-black);
  border-color: var(--full-black);
}

.button__isSecondary {
  background-color: var(--white);
  border-color: var(--grey-40);
  color: var(--black);
}

.button__isSecondary:hover {
  background-color: var(--grey-20);
  border-color: var(--grey-40);
}

.button__isSecondary:disabled {
  color: var(--grey-40);
  background-color: var(--white);
  border-color: var(--grey-40);
}

.button__isTertiary {
  background-color: var(--grey-20);
  border-color: var(--grey-20);
  color: var(--black);
}

.button__isTertiary:hover {
  background-color: var(--grey-40);
  border-color: var(--grey-40);
}

.button__isTertiary:disabled {
  background-color: var(--grey-10);
  border-color: var(--grey-10);
}

.button__isGreen {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--white);
}

.button__isGreen:hover {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}

.button__isViolet {
  background-color: var(--violet);
  border-color: var(--violet);
  color: var(--white);
}

.button__isViolet:hover {
  background-color: var(--dark-violet);
  border-color: var(--dark-violet);
}

.button__hasInvertedSize {
  height: 44px;
  padding: 3px 30px;
}

.button__isFullWidth {
  width: 100%;
}

.content {
  visibility: unset;
  width: 100%;
}

.content__isHidden {
  visibility: hidden;
}

.content__truncateText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (--medium-viewport) {
  .button__hasInvertedSize {
    height: 32px;
    padding: 3px 30px;
  }
}
