@import "../../../styles/colors.css";

.container {
  color: var(--black);
  height: 44px;
  padding: 8px 0;
  text-align: right;
  margin: 0 -2px;
}

.container__hidden {
  display: none;
}
