@import "../../../styles/colors.css";

.container {
  composes: london2 from "~styles/typography/styles.css";
  color: var(--black);
  flex-direction: column;
  align-self: center;
  padding-left: 1em;
}

.totalQuantity {
  composes: leavesden2 from "~styles/typography/styles.css";
  margin-left: 1ex;
}
