@import "../../styles/colors.css";
@import "../../styles/viewports.css";
@import "../../styles/zindex.css";

.wrapper {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  z-index: var(--search-over-backdrop-z-index);
  overflow: auto;
  display: flex;
  align-items: center;
}

.container {
  margin: auto;
  box-sizing: border-box;
  max-width: 356px;
  min-height: 446px;
  padding: 16px;
  position: relative;
  background-color: var(--white);
  display: flex;
  flex: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .container {
    width: 356px;
    height: 456px;
  }

  .container__notLoading {
    height: auto;
  }
}

@media (--medium-viewport) {
  .container {
    padding: 30px;
    padding-top: 25px;
  }
}

.container__withError {
  min-height: 466px;
}

@media (--smallish-viewport) {
  .container__withError {
    min-height: 456px;
  }
}
