@import "../../styles/colors.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";

.container {
  background-color: var(--grey-10);
}

.notificationContainer {
  position: relative;
  top: -16px;
  right: -5px;
}

@media (--medium-viewport) {
  .notificationContainer {
    top: -18px;
  }
}

.link {
  composes: asosFocus from "~styles/accessibility.css";
  composes: leavesden2 from "~styles/typography/styles.css";
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 50px;
  margin-left: 11px;
  color: var(--black);
  border-top: 1px solid var(--grey-40);
  position: relative;
  text-decoration: none;
}

.link:first-of-type {
  border-top: none;
}

.link:last-of-type {
  border-bottom: none;
}

.link:hover {
  color: var(--blue);
}

.link:hover > span {
  background-color: var(--blue);
}
