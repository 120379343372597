@import "../../../styles/colors.css";

.skipLink {
  composes: asosFocus from "~styles/accessibility.css";
  composes: leavesden2 from "~styles/typography/styles.css";
  position: absolute;
  clip: rect(0,0,0,0);
}

.skipLink:focus {
  position: relative;
  width: 100%;
  height: 2.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-90);
  text-transform: uppercase;
  text-decoration: none;
}

.skipLink:focus:hover {
  text-decoration: underline;
}
