/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/viewports.css";
@import "../../../styles/zindex.css";

.container {
  background: var(--white);
  border-top: 1px solid var(--grey-40);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--welcome-message-z-index);
  padding: 24px;
}

.wrapper {
  display: flex;
  justify-content: center;
  line-height: 24px;
  height: 100%;
  animation: slideUp 300ms ease-in forwards 1s;
  letter-spacing: 0.4px;
}

@keyframes slideUp {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

.flag {
  width: 23px;
  height: 23px;
  margin: 6px 16px 0 0;
}

.text {
  color: var(--black);
  margin-right: 0.5em;
}

.redirectLink {
  margin-right: 16px;
  color: var(--black);
}

.close {
  --icon: svg-inline(close);

  composes: blackIcon from "~styles/icons/styles.css";
  border: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 9px;
  top: 9px;
  mask-size: 100%;
  padding:0;
}

.close:hover {
  background-color: var(--blue);
}

.closeIcon {
  width: 100%;
  height: 100%;
}

.buttonsContainer { 
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mvtWrapper {
  max-width: 290px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.primaryCopyWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.primaryCopy {
  composes: bodyText from "~styles/typography/styles.css";
  font-weight: bold;
  line-height: 24px;
}

.mvtWrapper .flag {
  margin-top: 3px;
}

.secondaryCopy {
  composes: bodyText from "~styles/typography/styles.css";
  margin-bottom: 1em;
  line-height: 24px;
}

.separator { 
  margin: 0.9em 0;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

@media (--smallish-viewport) {
  .container {
    padding: 16px;
  }
}

@media (--medium-viewport) {
  .closeButton svg {
    height: 20px;
    width: 20px;
  }
}
