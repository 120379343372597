/**
 * This file should only ever be referenced using:
 *
 *   composes: <style> from "~styles/accessibility.css";
 *
 * Using `@import` will result in duplicate CSS in our bundles.
 */

@import "./colors.css";

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.asosFocus:focus {
  outline: 5px auto -moz-mac-focusring;
  outline: 5px auto -moz-focusring;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
