@import "../../styles/colors.css";
@import "../../styles/typography/variables.css";

.notification span {
  display: inline-block;
}

.miniBag {
  position: relative;
}

.link {
  composes: leavesden3 from "~styles/typography/styles.css";
  text-decoration: underline;
  color: var(--black);
}

.progressIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 290px;
}

.error {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
