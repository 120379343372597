/* stylelint-disable property-disallowed-list */
@value web-fonts-base-path: https://assets.asosservices.com/MasterLayout/WebFonts;
@value web-fonts-futura-pt-book: FTN45__W;
@value web-fonts-futura-pt-heavy: FTN75__W;
@value web-fonts-futura-pt-oblique: FTN46__W;
@value web-fonts-futura-pt-extra-bold-oblique: FTN96__W;

/* Generated by ParaType (http://www.paratype.com)
   Font Futura PT Book: Copyright © ParaType, 2015. All rights reserved.
   Font Futura PT Heavy: Copyright © ParaType, 2015. All rights reserved.
   Font Futura PT Extra Bold: Copyright © ParaType, 2015. All rights reserved. */

/* Necessary for legacy for some consumers */
@font-face {
  font-family: "FuturaStd";
  src: url("web-fonts-base-path/web-fonts-futura-pt-book/web-fonts-futura-pt-book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FuturaStd";
  src: url("web-fonts-base-path/web-fonts-futura-pt-heavy/web-fonts-futura-pt-heavy.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "FuturaStd";
  src: url("web-fonts-base-path/web-fonts-futura-pt-oblique/web-fonts-futura-pt-oblique.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "FuturaStd";
  src: url("web-fonts-base-path/web-fonts-futura-pt-extra-bold-oblique/web-fonts-futura-pt-extra-bold-oblique.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "FuturaStdBookOblique";
  src: url("web-fonts-base-path/web-fonts-futura-pt-oblique/web-fonts-futura-pt-oblique.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
