@import "../../../../styles/viewports.css";
@import "../MenuItem/index.css";

.flatMenuItem {
  height: 41px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

@media (--smallish-viewport) {
  .flatMenuItem {
    height: 48px;
  }
}
