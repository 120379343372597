@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/layout.css";
@import "../../../styles/viewports.css";
@import "../../../styles/zindex.css";

.dialog {
  height: 100%;
  position: fixed;
  top: 0;
  left: calc(-1 * var(--small-screen-side-panel-width));
  z-index: var(--navigation-sidepanel-dialog-z-index);
  background-color: var(--white);
  transition: left 0.25s ease-out;
}

.dialog__visible {
  left: 0;
}

.close {
  composes: asosFocus from "~styles/accessibility.css";
  display: none;
  position: absolute;
  padding: 0 13px;
  box-sizing: content-box;
  top: 0;
  left: var(--small-screen-side-panel-width);
  height: var(--small-screen-header-height);
  width: 24px;
  background-image: svg-inline(close);
  background-repeat: no-repeat;
  background-color: var(--black);
  background-size: 24px;
  background-position: 50%;
  cursor: pointer;
  border: 0;
}

.dialog__visible .close {
  display: initial;
}

.overflowHiddenContainer {
  display: none;
  position: relative;
  clip-path: inset(0px 0px);
  height: 100%;
  width: 100%;
}

.dialog__visible .overflowHiddenContainer,
.dialog__animating .overflowHiddenContainer {
  display: block;
}

.container {
  height: 100%;
  width: var(--small-screen-side-panel-width);
  background-color: var(--grey-20);
}

.primarySideContainer {
  position: absolute;
  height: calc(100% - var(--small-screen-header-height));
  left: 0;
  transition: left 0.25s ease-out;
}

.primarySideContainer__hidden {
  left: calc(-1 * var(--small-screen-side-panel-width));
}

.content {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.secondarySideContainer {
  visibility: hidden;
}

.secondarySideContainer__visible {
  visibility: visible;
}

.overlay {
  position: fixed;
  visibility: hidden;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: var(--navigation-sidepanel-overlay-z-index);
  opacity: 0;
  background-color: var(--backdrop);
  right: 0;
  transition: opacity 0.25s ease-in;
}

.overlay__show {
  visibility: visible;
  opacity: 1;
}

.srOnly {
  composes: srOnly from "~styles/accessibility.css";
}

@media (--smallish-viewport) {
  .dialog {
    left: calc(-1 * var(--smallish-and-medium-screen-side-panel-width));
    transition: left 0.25s ease-out;
  }

  .dialog__visible {
    left: 0;
  }

  .container {
    width: var(--smallish-and-medium-screen-side-panel-width);
  }

  .close {
    left: var(--smallish-and-medium-screen-side-panel-width);
  }

  .primarySideContainer {
    left: 0;
    transition: left 0.25s ease-out;
  }

  .primarySideContainer__hidden {
    left: calc(-1 * var(--smallish-and-medium-screen-side-panel-width));
  }
}

@media (--medium-viewport) {
  .dialog {
    top: var(--medium-screen-header-height);
    left: calc(-1 * var(--smallish-and-medium-screen-side-panel-width));
    transition: left 0.25s ease-out;
  }

  .dialog__visible {
    left: 0;
  }

  .container {
    width: var(--smallish-and-medium-screen-side-panel-width);
    height: calc(100% - var(--medium-screen-header-height));
  }

  .close {
    height: var(--medium-screen-header-height);
    width: 40px;
    left: 0;
    top: calc(-1 * var(--medium-screen-header-height));
  }

  .primarySideContainer {
    left: 0;
    transition: left 0.25s ease-out;
  }

  .primarySideContainer__hidden {
    left: calc(-1 * var(--smallish-and-medium-screen-side-panel-width));
  }
}

@media (--large-viewport) {
  .dialog,
  .dialog__visible {
    display: none;
  }
}
