@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";

:root {
  --icon-width: 1.25em;
  --gap-between-icon-and-text: 0.75em;
  --close-hit-area: 44px;
  --close-padding: calc((var(--close-hit-area) - var(--base-icon-size)) / 2);
}

.notification {
  background-repeat: no-repeat;
  background-position: 0 5px;
  padding: 0;
}

.text {
  composes: leavesden3 from "~styles/typography/styles.css";
  flex-grow: 1;
  text-align: left;
}

.hasBackground {
  background-size: 1rem 1rem;
  background-color: var(--grey-10);
  padding: 0.6rem;
  display: flex;
  flex-direction: row;
  color: var(--black);
  align-items: center;
}

.notification.hasBackground {
  background-position: left 0.75em center;
}

.hasIcon.success {
  background-image: svg-inline(notification-check-green);
}

.success.hasBackground {
  background-size: 1rem;
  background-color: var(--light-green);
}

.hasIcon.error {
  background-image: svg-inline(notification-dark);
}

.error.hasBackground {
  background-color: var(--light-red);
}

.hasIcon.promotion {
  background-image: svg-inline(notification-dark);
}

.promotion.hasBackground {
  background-color: var(--light-blue);
}

.hasIcon.alert {
  background-image: svg-inline(notification);
}

.alert.hasBackground {
  background-color: var(--light-amber);
}

.hasIcon {
  padding-left: 2em;
  position: relative;
  text-align: left;
}

.hasIcon.hasBackground {
  padding-left: calc(
    1em + var(--icon-width) + var(--gap-between-icon-and-text)
  );
}

.close {
  --icon: svg-inline(close);

  composes: blackIcon from "~styles/icons/styles.css";
  box-sizing: content-box;
  padding: var(--close-padding);
  right: calc(0 - var(--close-padding));
  top: calc(0 - var(--close-padding));
}
