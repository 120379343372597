/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";
@import "../../styles/zindex.css";

.searchContainer {
  display: inline-block;
  flex-grow: 1;
}

.backdrop {
  background-color: var(--backdrop);
  position: fixed;
  top: -40px;
  left: 0;
  right: 0;
  height: calc(100vh + 40px);
  z-index: var(--search-backdrop-z-index);
  display: none;
  cursor: pointer;
}

.close {
  --icon: svg-inline(close);

  composes: darkIcon from "~styles/icons/styles.css";
  box-sizing: content-box;
  mask-size: 92%;
  cursor: pointer;
}

@supports (pointer-events: none) {
  .backdrop {
    display: block;
    opacity: 0;
    pointer-events: none;
  }
}

.backdrop__active {
  display: block;
  pointer-events: all;
  opacity: 1;
}

.controls {
  display: block;
  box-sizing: border-box;
  height: 64px;
  margin: 0 -3px;
  padding: 10px 0;
  text-align: right;
  vertical-align: middle;
}

.container {
  background-color: var(--grey-20);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: var(--search-container-z-index);
  overflow-y: auto;
  display: none;
}

.container__active {
  display: block;
}

.fieldContainer {
  position: relative;
  background: var(--grey-90);
  border-radius: 19px/50%;
  top: 42px;
}

.field {
  composes: bodyText from "~styles/typography/styles.css";
  composes: asosFocus from "~styles/accessibility.css";
  font-size: var(--standard-font-size);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
  height: 38px;
  border-radius: 19px/50%;
  padding-left: 16px;
  padding-right: 70px;
  box-sizing: border-box;
  color: var(--black);
  -webkit-appearance: none;
  background: var(--white);
}

.floatingLabel {
  position: absolute;
  top: -25px;
  left: 15px;
  color: var(--grey-90);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  backface-visibility: hidden;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
}

.field__active:placeholder-shown + .floatingLabel {
  opacity: 0;
  visibility: hidden;
}

.field__active {
  z-index: var(--search-fields-z-index);
}

.field__clear {
  position: absolute;
  top: 9px;
  right: 45px;
  z-index: 22001;
}

.field__clear span {
  width: 15px;
}

.field::-webkit-search-cancel-button,
.field::-webkit-search-decoration,
.field::-webkit-search-results-button,
.field::-webkit-search-results-decoration {
  display: none;
}

.field::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.button {
  background-color: transparent;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transition: background-color 0.3s;
  z-index: var(--search-fields-z-index);
  color: var(--black);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.button:focus {
  border: 2px solid var(--white);
  outline: none;
  box-shadow: 0 0 3px 1px var(--blue);
}

.button__active {
  color: var(--white);
  background-color: var(--blue);
}

.results {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 38px;
  padding: 16px 0;
  z-index: var(--search-results-z-index);
  overflow-y: auto;
}

.label {
  composes: srOnly from "~styles/accessibility.css";
}

@media (--medium-viewport) {
  .backdrop {
    transition: opacity 0.3s ease-in;
  }

  .controls {
    display: none;
  }

  .container {
    position: static;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: transparent;
    overflow-y: initial;
  }

  .fieldContainer {
    top: 0;
  }

  .searchContainer {
    padding: 11px 15px 11px 18px;
  }

  .results {
    top: 19px;
    background-color: var(--grey-20);
    padding: 32px 0 16px;
    max-height: 50vh;
    display: none;
  }

  .results__active {
    display: block;
  }

  .floatingLabel {
    display: none;
  }
}

@media (--large-viewport) {
  .searchContainer {
    padding: 11px 15px 11px 30px;
  }

  .floatingLabel {
    display: none;
  }
}
