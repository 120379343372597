@import "../../../../styles/colors.css";
@import "../../../../styles/borders.css";

:root {
  --circle-image-list-height: 52px;
  --circle-image-size: 40px;
}

.circleImageList {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(6, auto);
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  column-gap: 20px;
}

.circleImageLink {
  max-height: var(--circle-image-list-height);
  width: 100%;
}

.circleImageLink a {
  display: flex;  
  text-decoration: none;
  align-items: center;
}

.circleImageBorder {
  padding: 3px;
  border: var(--navigation-border);
}

.circleImage {
  background-position: 0 0;
  background-size: cover;
}

.circleImageBorder,
.circleImage {
  border-radius: 50%;
  display: inline-block;
  width: var(--circle-image-size);
  height: var(--circle-image-size);
  vertical-align: middle;
}

.labelContainer {
  composes: asosFocus from "~styles/accessibility.css";
  display: flex;
  height: var(--circle-image-list-height);
  border-bottom: var(--secondary-nav-divider);
  margin-left: 13px;
  padding-left: 7px;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.label {
  composes: leavesden3 from "~styles/typography/styles.css";
  composes: link from "~styles/typography/styles.css";
  color: var(--grey-90);
  
  /* Ellipsis styling */
  overflow: hidden;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.label__sale {
  color: var(--red);
}

.label__bold {
  composes: london3 from "~styles/typography/styles.css";
}

.label:focus {
  color: var(--blue);
}

/* grid sets the number of rows in a column to 6, if there are more than 6, subsequent rows will be added to columns to the right. This style removes the border so that bottom of every 6th cell does not show a divider, as per designs  */
.circleImageLink:nth-child(6n) .labelContainer,
/* ensures the final cell does not have a divider line on the bottom */
.circleImageLink:last-child .labelContainer {
  border: 0;
}

/* ensures the final cell does not have a divider line on the bottom */
.circleImageLink:last-child .labelContainer {
  border: 0;
}

.circleImageLink:hover span {
  color: var(--blue);
}

.circleImageLink:hover .circleImageBorder {
  border: var(--blue-border);
}

