@import "../../styles/colors.css";
@import "../../styles/zindex.css";

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: var(--search-backdrop-z-index);
  background-color: var(--backdrop);
  will-change: opacity;
  pointer-events: none;
}
