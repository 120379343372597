/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../../styles/colors.css";
@import "../../../../styles/typography/variables.css";
@import "../../../../styles/viewports.css";

:root {
  --circle-image-size-small: 62px;
  --circle-image-size-medium: 80px;
}

.container {
  display: block;
  margin-bottom: 16px;
  height: 78px;
}

.container:last-child {
  margin-bottom: 0;
}

.menuItem {
  composes: h4Text from "~styles/typography/styles.css";
  color: var(--black);
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 0 12px;
  background-color: var(--grey-20);
  display: flex;
  box-sizing: border-box;
}

.menuItem:hover {
  opacity: 0.7;
}

.label {
  font-size: var(--small-font-size);
  width: 140px;
  text-align: left;
  align-self: center;
  flex-grow: 1;
  padding-right: 10px;
}

.label__sale {
  color: var(--red);
}

.image {
  background-position: 0 0;
  background-size: var(--circle-image-size-small);
  width: var(--circle-image-size-small);
  height: var(--circle-image-size-small);
  border-radius: 50%;
  align-self: center;
  flex-grow: 0;
}

@media (--medium-viewport) {
  .container {
    height: 96px;
  }

  .menuItem {
    composes: h3Text from "~styles/typography/styles.css";
  }

  .label {
    width: 180px;
  }

  .image {
    background-size: var(--circle-image-size-medium);
    width: var(--circle-image-size-medium);
    height: var(--circle-image-size-medium);
  }
}

@media (--medium-viewport) {
  .label {
    font-size: var(--standard-font-size);
  }
}
