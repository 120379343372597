@import "../../../styles/borders.css";
@import "../../../styles/colors.css";
@import "../../../styles/layout.css";
@import "../../../styles/zindex.css";

.container {
  width: 100%;
  display: none;
}

.backdrop {
  width: 100%;
  height: 100vh;
  background-color: var(--backdrop);
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--navigation-backdrop-z-index);
}

.flyoutContainer {
  width: 100%;
  max-width: var(--large-screen-max-content-width);
  padding: 0 var(--large-screen-side-padding);
  margin: 0 auto;
  box-sizing: border-box;
}

.flyout {
  display: flex;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 10px;
  background-color: var(--grey-10);
  position: relative;
  z-index: var(--navigation-flyout-z-index);
  transition: min-height 200ms ease-in-out;
  min-height: 0;
}

.progressIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  width: 100%;
}

.visible {
  display: block;
}

.column {
  box-sizing: border-box;
  padding: 0 20px;
  border-right: var(--navigation-border);
}

.column:last-child {
  border-right: 0;
}

.textList {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 190px;
}

.colspan-1 {
  flex: 1;
}

.colspan-1 .textList {
  width: 100%;
}

.colspan-2 {
  flex: 2;
}

.colspan-2 .textList {
  width: 100%;
  column-count: 2;
}

.colspan-3 {
  flex: 3;
}

.colspan-3 .textList {
  width: 33%;
}

.colspan-4 {
  flex: 4;
}

.colspan-4 .textList {
  width: 25%;
}

.columnHeader {
  composes: london3 from "~styles/typography/styles.css";
  text-transform: uppercase;
  display: block;
  padding-bottom: 4px;
  margin-top: 0;
  margin-bottom: 8px;
  width: 100%;
}

.columnHeader span {
  color: var(--grey-90);
}

.textLink {
  composes: leavesden3 from "~styles/typography/styles.css";
  composes: link from "~styles/typography/styles.css";
  composes: asosFocus from "~styles/accessibility.css";
  box-sizing: border-box;
  padding: 0 5px;
  padding-top: 8px;
  margin: 0 -5px;
  height: 28px;
  color: var(--grey-90);
}

.textLink__sale {
  color: var(--red);
}

.textLink__bold {
  composes: london3 from "~styles/typography/styles.css";
}

.textLink:hover {
  color: var(--blue);
}

.textLink:focus {
  color: var(--blue);
}

.hidden {
  display: none;
}
