@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/typography/variables.css";
@import "../../../styles/viewports.css";

.content {
  margin: 0 auto;
  max-width: 488px;
  padding: 16px;
  flex: 1;
  display: flex;
  flex-flow: row wrap;
}

.message {
  composes: leavesden2 from "~styles/typography/styles.css";
  margin: 0 20px;
}

.closeContainer {
  padding: 0;
  width: 23px;
  height: 23px;
  margin-left: auto;
}

.close {
  --icon: svg-inline(close);
  
  composes: blackIcon from "~styles/icons/styles.css";
  cursor: pointer;
  mask-size: 15px;
}

.close:hover {
  background-color: var(--blue);
}

@media (--smallish-viewport) {
  .content {
    padding: 16px 12px;
  }

  .icon {
    flex: 1 0 auto;
  }
}

@media (--medium-viewport) {
  .content {
    padding: 16px 32px;
  }

  .icon {
    flex: none;
  }
}
