@import "../../../styles/viewports.css";

.asosLogo {
  filter: invert(1);
  width: auto;
  height: 16px;
}

.asosLogo:hover,
.asosLogo:focus {
  opacity: 0.95;
}

@media (--medium-viewport) {
  .asosLogo {
    height: 20px;
  }
}

.link {
  composes: asosFocus from "~styles/accessibility.css";
  display: flex;
  padding-right: 20px;
  align-items: center;
}

@media (--large-viewport) {
  .link {
    margin-left: -20px;
    padding: 0 20px;
  }
}
