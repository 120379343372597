@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/layout.css";
@import "../../../styles/viewports.css";

:root {
  --header-size: 50px;
  --nav-padding: 16px;
  --z-index-nav-container: 5;
  --z-index-close-button: 6;
}

.container {
  position: absolute;
  top: 0;
  left: var(--small-screen-side-panel-width);
  width: 100%;
  height: 100%;
  z-index: var(--z-index-nav-container);
  transition: left 0.25s ease-out;
  visibility: hidden;
  background-color: var(--grey-20);
}

.container__active {
  left: 0;
}

.container__visible {
  visibility: visible;
}

.header {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: var(--header-size);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-20);
  box-sizing: border-box;
}

.sectionTitle {
  composes: london2 from "~styles/typography/styles.css";
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  overflow: hidden;
  padding: 0 var(--header-size);
}

.closeButton {
  composes: resetButton from "~styles/button.css";
  background-image: svg-inline(back);
  background-repeat: no-repeat;
  background-position: 50%;
  fill: var(--black);
  width: var(--header-size);
  height: var(--header-size);
  flex-grow: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-close-button);
}

.secondary {
  background: var(--grey-20);
  position: absolute;
  top: var(--header-size);
  left: 0;
  height: calc(100% - var(--header-size));
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.secondary > ul li:last-child > ul > li:last-child {
  border-bottom: var(--nav-padding) solid var(--white);
}

.item {
  display: none;
  position: relative;
  height: 100%;
}

.item__visible {
  display: block;
}

@media (--smallish-viewport) {
  .container {
    left: var(--smallish-and-medium-screen-side-panel-width);
  }

  .container__active {
    left: 0;
  }
}

@media (--medium-viewport) {
  .secondary {
    height: calc(100% - var(--header-size) - 10px);
  }
}

.progressIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
