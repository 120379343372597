.list {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  width: 100%;
}

.list__animate {
  transition: transform 0.3s;
}

.carouselLink {
  composes: asosFocus from "~styles/accessibility.css";
  display: inline-block;
  width: 100%;
}

.carouselLink:last-child {
  margin-right: 0;
}
