@import "../../styles/colors.css";

.alert {
  background-color: var(--light-amber);
}

.success {
  background-color: var(--light-green);
}

.error {
  background-color: var(--light-red);
}
