@import "../../styles/colors.css";

.countryFlag {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid var(--grey-10);
  outline: 1px solid currentColor;
  box-sizing: border-box;
}
