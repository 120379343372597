@import "../../../styles/colors.css";
@import "../../../styles/layout.css";
@import "../../../styles/viewports.css";

@media (--large-viewport) {
  html[data-gender="men" i] .largeNavigation,
  html[data-gender="women" i] .largeNavigation {
    background-color: var(--grey-00);
    display: block;
    height: var(--navigation-header-height);
    position: relative;
  }
}
