/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../../styles/colors.css";
@import "../../../../styles/layout.css";
@import "../../../../styles/typography/variables.css";
@import "../../../../styles/viewports.css";

.block {
  display: block;
  margin-top: 15px;
}

.fakeOutsideBlock {
  display: inline-block;
  width: 100%;
  margin: calc(var(--margin-sizing) * -1) calc(var(--margin-sizing) * -1) 0;
  background: var(--grey-20);
  padding: var(--margin-sizing);
}

.block a {
  font-size: var(--small-font-size);
  display: block;
  color: var(--white);
  letter-spacing: 2px;
  line-height: 1.75;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  height: 45px;
  background-color: var(--black);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

@media (--medium-viewport) {
  .block a {
    font-size: var(--standard-font-size);
  }
}
