@import "../../../styles/colors.css";
@import "../../../styles/borders.css";

.socialLinks {
  height: 60px;
  display: inline-block;
  vertical-align: top;
}

.socialLink {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 15px 0;
  margin-left: 50px;
  white-space: nowrap;
  padding: 1px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--white);
  text-transform: capitalize;
  position: relative;
}

.socialLink:first-child {
  margin-left: 0;
}

.socialLink:hover {
  color: transparent;
  border: var(--blue-border);
}

.link {
  position: relative;
  display: block;
  height: 100%;
}

.srOnly {
   composes: srOnly from "~styles/accessibility.css";
}