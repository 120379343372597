@import "../../../../../styles/colors.css";

.dotContainer {
  position: absolute;
  bottom: -4px;
}

.dotItem {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: var(--grey-40);
  vertical-align: top;
  outline: none;
}

.dot:last-child {
  margin-right: 0;
}

.dot__selected {
  background-color: var(--blue);
}
