@import "../../styles/colors.css";
@import "../../styles/layout.css";
@import "../../styles/viewports.css";

.container {
  background-color: var(--black);
  height: var(--small-screen-header-height);
  width: 100%;
}

.body {
  composes: bodyText from "~styles/typography/styles.css";
  color: var(--grey-90);
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.leftBody {
  display: flex;
  width: 100%;
}

@media (--medium-viewport) {
  .container {
    height: var(--medium-screen-header-height);
  }
}

@media (--large-viewport) {
  .body {
    width: 100%;
    max-width: var(--large-screen-max-content-width);
    padding: 0 var(--large-screen-side-padding);
    box-sizing: border-box;
  }
}
