/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/viewports.css";

.container {
  list-style: none;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(var(--asos-black-rgb), 0.1);
  box-sizing: border-box;
}

.container__large {
  display: none;
}

.container li, .container__small_and_medium button {
  width: 50%;
  position: relative;
  display: inline-block;
}

.container__small_and_medium button:not(:last-of-type)::after {
  content: "";
  top: 50%;
  right: 0;
  margin-top: -10px;
  position: absolute;
  height: 20px;
  width: 1px;
  background-color: rgba(var(--asos-black-rgb), 0.1);
}

.button {
  composes: bodyText from "~styles/typography/styles.css";
  composes: asosFocus from "~styles/accessibility.css";
  height: 49px;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  color: var(--grey-70);
  letter-spacing: 2px;
  font-weight: 900;
  background-color: var(--white);
  border-style: none;
  cursor: pointer;
  padding: 0;
  line-height: 50px;
  box-sizing: border-box;
}

.button.selected {
  color: var(--black);
  border-bottom: 2px solid var(--black);
}

@media (--large-viewport) {
  .container {
    height: 100%;
    padding: 0;
    width: 230px;
    border-bottom: none;
  }

  .container__large {
    display: inline-block;
  }

  .container li {
    width: 115px;
    border-right: 1px solid var(--grey-00);
    border-bottom: none;
    box-sizing: border-box;
  }

  .container li:first-of-type {
    border-left: 1px solid var(--grey-00);
  }

  .button {
    height: 100%;
    line-height: 60px;
    width: 100%;
    color: var(--white);
    background-color: var(--black);
    border-bottom: none;
  }

  .button.selected,
  .button.selected:focus,
  .button.selected:hover {
    color: var(--white);
    background-color: var(--grey-00);
    border-bottom: none;
    height: 100%;
  }

  .button:focus,
  .button:hover {
    color: var(--white);
    background-color: var(--floor-nav-focus);
  }
}
