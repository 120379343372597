/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../styles/colors.css";
@import "../../../styles/layout.css";
@import "../../../styles/viewports.css";


/* TODO: (Scout) there is both logic to hide this in code and in css  */
.container {
  display: none;
}

@media (--large-viewport) {
  .container {
    background-color: var(--grey-00);
    height: var(--navigation-header-height);
    display: block;
    position: relative;
  }

  .container__hidden,
  .container.container__hidden[aria-hidden="false"] {
    display: none;
  }

  .navigation {
    margin: 0 auto;
    max-width: var(--large-screen-max-content-width);
    padding: 0 var(--large-screen-side-padding);
    box-sizing: border-box;
    overflow: hidden;
  }

  .navButtons {
    display: block;
    height: var(--navigation-header-height);
  }

  .navButton {
    composes: asosFocus from "~styles/accessibility.css";
    composes: bodyText from "~styles/typography/styles.css";
    color: var(--white);
    letter-spacing: 0.8px;
    line-height: var(--navigation-header-height);
    border: none;
    padding: 0 10px;
    background-color: var(--grey-00);
    margin: 0;
    outline: none;
    border-radius: 0;
  }

  .navButton__label {
    position: relative;
  }

  .navButton:hover,
  .navButton__active, .navButton:focus {
    color: var(--black);
    background-color: var(--grey-20);
    font-weight: 400;
  }

  .slant {
    composes: asosFocus from "~styles/accessibility.css";
    display: block;
    padding: 0 12px 0 14px;
    font-weight: 800;
    background-color: var(--red);
    transform: skew(-12deg);
  }

  .slant span {
    display: block;
    transform: skew(12deg);
  }

  .navButton__active .slant,
  .navButton:hover .slant,
  .navButton:focus .slant {
    color: var(--black);
    background-color: transparent;
    padding: 0 13px;
  }

  .secondaryNav {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
  }

  .bold {
    font-weight: 800;
  }
}
