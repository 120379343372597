/**
 * This file should only ever be referenced using:
 *
 *   composes: <style> from "~styles/button.css";
 *
 * Using `@import` will result in duplicate CSS in our bundles.
 */

.resetButton {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 0;
}
