@import "../../../styles/colors.css";

.option {
  composes: leavesden2 from "~styles/typography/styles.css";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 32px;
  vertical-align: middle;
  color: var(--black);
}

.option__active,
.option:active,
.option:hover,
.option:focus {
  background-color: var(--grey-40);
  outline: none;
  box-shadow: inset 0 0 3px 1px var(--blue);
}

.count {
  float: right;
  color: var(--warmer-grey);
  text-align: right;
}

.bold {
  composes: london2 from "~styles/typography/styles.css";
  pointer-events: none;
}
