@import "../../styles/icons/variables.css";
@import "../../styles/viewports.css";

.burger {
  height: 100%;
  width: 22px;
  padding: 0 18px;
  box-sizing: content-box;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  background-color: var(--black);
}

.icon {
  --icon: svg-inline(burger);

  composes: asosFocus from "~styles/accessibility.css";
  composes: lightIcon from "~styles/icons/styles.css";
  margin-top: 2px;
  margin-left: -1px;
  mask-size: 18px 14px;
}

.burger__hidden {
  display: none;
}

@media (--medium-viewport) {
  .burger {
    width: 30px;
    padding: 0 16px 0 18px;
  }

  .icon {
    margin-left: -2px;
  }
}

@media (--large-viewport) {
  .burger {
    display: none;
  }
}
