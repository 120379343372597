@import "../../../../styles/colors.css";
@import "../../../../styles/layout.css";
@import "../../../../styles/viewports.css";

.container {
  padding: var(--navigation-margin) var(--navigation-margin) 0px var(--navigation-margin);
  background-color: var(--white);
  box-sizing: border-box;
  width: var(--small-screen-side-panel-width);
}

.container__noBackground {
  background-color: transparent;
}

.container:first-child {
  padding-bottom: 0;
}

.container:last-child {
  margin-bottom: 0;
}

@media (--smallish-viewport) {
  .container {
    width: var(--smallish-and-medium-screen-side-panel-width);
  }
}
