/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/typography/variables.css";

.asosAppSmartBanner {
  position: fixed !important;
  left: 0;
  bottom: -1px;
  width: 100%;
  min-height: 78px;
  border-top: 1px solid var(--grey-20);
  background: var(--white);
  z-index: 999;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.asosAppSmartBanner * {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
}

.asosAppSmartBanner a {
  text-decoration: none;
}

.asosAppSmartBanner .sbClose {
  top: 20px;
  right: 10px;
  width: 30px;
  padding: 10px;
  color: var(--black);
  display: block;
  position: absolute;
  border: none;
  background-color: var(--white);
  text-indent: -9999px;
}

.asosAppSmartBanner .sbMessageView {
  padding: 17px 10px 10px;
}

.asosAppSmartBanner .sbMessage {
  display: block;
  margin: 7px 10px 17px 67px;
  color: var(--black);
  position: relative;
}

.asosAppSmartBanner .sbMessage,
.asosAppSmartBanner .sbMessage * {
  font-size: 13px;
}

@media screen and (min-width: 480px) {
  .asosAppSmartBanner .sbMessageActions {
    margin-right: 30px;
  }
}

.asosAppSmartBanner .sbInstall {
  background-color: var(--green);
  color: var(--white);
}

.asosAppSmartBanner .sbActions.iOs9 {
  text-align: center;
}

.asosAppSmartBanner .sbActions.iOs9 .sbInstall {
  width: 200px;
}

@media only screen and (max-width: 1024px) and (min-width: 569px) {
  .asosAppSmartBanner .sbMessage {
    max-width: 300px;
    margin-right: 20px;
    float: left;
  }

  .asosAppSmartBanner .sbActions {
    float: right;
    max-width: 400px;
    min-width: 300px;
  }

  .asosAppSmartBanner .sbClose {
    top: 16px;
  }
}

.asosAppSmartBanner .sbMessage.sbShowRatings .asosName {
  font-weight: bold;
  position: relative;
}

.asosAppSmartBanner .sbMessage.sbShowRatings .asosName::after {
  margin-bottom: 0;
  text-indent: -9999px;
  display: block;
  width: 82px;
  height: 15px;
  top: 1px;
  left: 42px;
  content: " ";
  position: absolute;
}

.asosAppSmartBanner.android .sbMessage.sbShowRatings .asosName::after {
  width: 76px;
}

.asosAppSmartBanner .sbMessage > div {
  margin-bottom: 5px;
}

.asosAppSmartBanner .sbMessage .promoMessage {
  background: var(--black);
  color: var(--white);
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding: 5px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.asosAppSmartBanner .sbMessage .sbAppIcon {
  position: absolute;
  top: 50%;
  left: -67px;
  transform: translateY(-50%);
  width: 55px;
  height: 55px;
  background: no-repeat 50% 50%;
  background-image: url(https://mobile.asosservices.com/web/smartbanners/new_logo.png);
  background-size: 55px;
  border: 1px solid var(--grey-20);
  border-radius: 10px;
  margin-bottom: 0;
}

.asosAppSmartBanner .sbBtns {
  font-family: var(--primary-font);
  color: var(--black);
  font-size: 11px;
  font-weight: bold;
  padding: 4px 0;
  display: inline-block;
  width: 46%;
  max-width: 200px;
  margin: 5px;
  text-align: center;
  text-transform: uppercase;
  height: 34px;
  line-height: 29px;
  letter-spacing: 0.4px;
  border: none;
}

.asosAppSmartBanner .sbOpenInApp {
  background-color: var(--grey-10);
}

.asosAppSmartBanner .sbSettingsViewAction {
  height: 68px;
  padding: 7px;
  line-height: 24px;
  text-align: center;
}

.asosAppSmartBanner .sbSettingsView {
  display: none;
}

.asosAppSmartBanner .sbSettingsView label {
  margin-left: 7px;
}

.asosAppSmartBanner #sb-closeSettingsView.sbClose {
  float: right;
}

.asosAppSmartBanner.withPromo .sbClose {
  top: 5px;
  right: 19px;
}

.asosAppSmartBanner.withPromo .sbMessage .asosName {
  margin-bottom: 10px;
}

.asosAppSmartBanner.withPromo .sbMessage {
  margin-bottom: 13px;
}

/* iphone 5 small resolution makes text extend outside button */
body[lang="ru-RU"] .asosAppSmartBanner .sbBtns {
  font-size: 9px;
}

#unPdpPage .sbBtns {
  font-size: 12px;
}

#unPdpPage .sbMessage {
  font-size: 14px;
}

.asosAppSmartBanner .sbMessage .smaller {
  font-size: 0.8em !important;
}
