@import "../../styles/zindex.css";

.fade {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--search-backdrop-z-index);
  will-change: opacity;
  transform: translateZ(0);
}

.fade__entered {
  opacity: 0.01;
}

.fade__entering {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.fade__exited {
  opacity: 1;
}

.fade__exiting {
  opacity: 0.01;
  transition: opacity 0.4s ease-in;
}
