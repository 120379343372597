@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/typography/variables.css";
@import "../../../styles/viewports.css";

.content {
  margin: 0 auto;
  max-width: 1300px;
  padding: 16px;
  flex: 1;
  display: flex;
}

.icon {
  background-position: center;
}

.alert {
  background-size: 20px;
  background-image: svg-inline(alert);
}

.success {
  background-size: 20px;
  background-image: svg-inline(notification-check-green);
}

.message {
  composes: leavesden2 from "~styles/typography/styles.css";
  margin: 0 20px;
}

.closeContainer {
  padding: 0;
  width: 23px;
  height: 23px;
  margin-left: auto;
}

.close {
  --icon: svg-inline(close);

  composes: blackIcon from "~styles/icons/styles.css";
  cursor: pointer;
  mask-size: 15px;
}

.close:hover {
  background-color: var(--blue);
}

@media (--smaller-viewport) {
  .content {
    padding: 16px 12px;
  }

  .icon {
    flex-shrink: 0;
  }
}

@media (--medium-viewport) {
  .content {
    padding: 16px 32px;
  }

  .icon {
    flex-shrink: auto;
  }
}
