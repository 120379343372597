@import "../../../../styles/colors.css";
@import "../../../../styles/layout.css";
@import "../../../../styles/borders.css";

.container {
  display: flex;
  margin-top: auto;
  flex-shrink: 0;
}

.container__column {
  height: auto;
  flex-direction: column;
  justify-content: space-between;
}

.marketingItem {
  padding: 2px;
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0 10px;
  box-sizing: border-box;
  border: 1px solid var(--grey-40);
}

.marketingItem:first-child {
  margin-left: 0;
}

.marketingItem:last-child {
  margin-right: 0;
}

.marketingItem:focus,
.marketingItem:hover {
  border: var(--blue-border);
}

.container__column .marketingItem {
  margin: 0 0 10px;
  flex-shrink: 0;
}

.container__column .marketingItem:last-child {
  margin-bottom: 0;
}

.label {
  composes: london2 from "~styles/typography/styles.css";
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 var(--large-screen-side-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
}

.label__dark {
  color: var(--black);
}

.label__light {
  color: var(--white);
}

.image {
  display: block;
  width: 100%;
  position: relative;
}

.thumbnail {
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  bottom: 2px;
}

.vertical {
  background-repeat: no-repeat;
  background-size: 100% 70%;
  background-position: center bottom;
}

.vertical.light {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--asos-white-rgb), 0),
    rgba(var(--asos-black-rgb), 0.75)
  );
}

.vertical.dark {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--asos-white-rgb), 0),
    rgba(248, 248, 248, 0.8)
  );
}

.vertical + span {
  height: 3.375em;
  top: auto;
  bottom: 0.625em;
}

.horizontal {
  text-align: left;
  background-repeat: no-repeat;
  background-size: 75% 100%;
  background-position: left center;
}

.horizontal .light {
  background-image: linear-gradient(
    to left,
    rgba(var(--asos-white-rgb), 0),
    rgba(var(--asos-black-rgb), 0.75)
  );
}

.horizontal .dark {
  background-image: linear-gradient(
    to left,
    rgba(var(--asos-white-rgb), 0),
    rgba(248, 248, 248, 0.8)
  );
}

.horizontal + span {
  height: 100%;
  text-align: left;
  padding-left: 0.625em;
  width: 70%;
}

.noTitle {
  composes: srOnly from "~styles/accessibility.css";
}