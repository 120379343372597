/**
 * This file should only ever be referenced from `Root.js`.
 *
 * It should only ever contain global styles for both Site Chrome and consuming
 * apps.
 */

@import "./colors.css";
@import "./viewports.css";
@import "./reset.css";
@import "./legacyTypography.deferred.css";

html {
  height: 100%;
  text-size-adjust: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

button {
  color: var(--full-black);
}

ul,
li {
  list-style: none;
  padding: 0;
}

select {
  color: var(--full-black);
}

:global(.chrome-hide-overflow) {
  overflow: hidden;
}

:global(.chrome-main-content--accessible) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global(#chrome-app-container) {
  flex: 1 0 auto;
}

:global(#chrome-main-content) {
  height: 100%;
}

@media (--medium-viewport) {
  :global(#globalBannerComponent) {
    background-color: var(--grey-20);
    border-bottom: 1px solid var(--grey-40);
    box-sizing: border-box;
    display: block;
    height: 50px;
    position: relative;
    width: 100%;
  }
}
