@import "../../../styles/colors.css";
@import "../../../styles/typography/variables.css";

.recentSearchesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  text-transform: uppercase;
  color: var(--grey-90);
}

.recentSearchesClearButton {
  composes: resetButton from "~styles/button.css";
  color: var(--black);
}

.recentSearchesHeader,
.recentSearchesClearButton {
  composes: london3-button from "~styles/typography/styles.css";
  text-transform: uppercase;
}