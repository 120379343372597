/* stylelint-disable property-disallowed-list */

/*  
    efforts to localise all typographic styles to a 
    single location (ab#829689) are not relevant to icon font sizing 
*/

.widgetIcon {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.container {
  font-size: 1.25rem; /* 50px, default icon height (based on base font-size 16px) */
  display: inline-block;
  color: currentcolor;
  width: 1em;
  height: 1em;
  box-sizing: border-box;
}

.medium {
  font-size: 1.25rem;
}

.small {
  font-size: 1rem;
}

.container > svg {
  width: 100%;
  fill: currentcolor;
}
