/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../styles/colors.css";
@import "../../styles/layout.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";

.container {
  composes: bodyText from "~styles/typography/styles.css";
  font-size: var(--smallest-font-size);
  background-color: var(--grey-40);
  color: var(--grey-90);
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding-left: var(--small-screen-side-padding);
}

.body {
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.copyright {
  color: var(--black);
  line-height: var(--small-screen-header-height);
  margin: 0;
  flex: 0 0 85px;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  clip-path: polygon(
    0 0,
    calc(100% - var(--small-screen-side-padding)) 0,
    calc(100% - var(--small-screen-side-padding)) 100%,
    0 100%
  );
  text-align: right;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.links li {
  display: inline-block;
  position: relative;
  padding-right: 1em;
}

.pipe::after {
  content: "|";
  display: inline;
  position: absolute;
  right: 3px;
}

.links a {
  color: var(--black);
  text-decoration: none;
  display: inline-block;
  padding: 0 4px;
}

.links a:hover,
.links a:focus {
  color: var(--blue);
}

.links li:last-child a {
  margin-right: 5px;
}

li.hideOnSmallViewport {
  display: none;
}

@media (--medium-viewport) {
  .container {
    padding: 0;
  }

  .body {
    padding: 0 var(--medium-screen-side-padding);
  }

  .links {
    margin-right: calc(-5px - var(--small-screen-side-padding));
  }

  li.hideOnSmallViewport {
    display: inline-block;
  }
}

@media (--large-viewport) {
  .container {
    font-size: 0.875;
  }

  .body {
    max-width: var(--large-screen-max-content-width);
    padding: 0 var(--large-screen-side-padding);
    width: 100%;
    box-sizing: border-box;
  }

  .links li {
    line-height: var(--small-screen-header-height);
  }
}
