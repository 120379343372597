@import "../../styles/colors.css";
@import "../../styles/icons/variables.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";
@import "../../styles/zindex.css";

:root {
  --icon-size: 18px;
}

.container {
  left: 0;
  width: 100%;
  background-color: var(--light-amber);
  color: var(--black);
  border: 0;
  display: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: var(--unsupported-browser-z-index);
}

.content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  text-align: left;
}

.message {
  padding-left: calc(var(--icon-size) + 40px);
}

.message::before {
  position: absolute;
  left: 18px;
  width: var(--icon-size);
  height: var(--icon-size);
  content: "";
  background: svg-inline(notification) 50% 50% no-repeat;
}

.header {
  composes: london3 from "~styles/typography/styles.css";
  text-transform: uppercase;
}

.details {
  composes: leavesden3 from "~styles/typography/styles.css";
  margin-top: 4px;
}

@media (--medium-viewport) {
  .message {
    padding-left: calc(var(--icon-size) + 48px);
  }

  .message::before {
    left: 24px;
  }
}

@media (--large-viewport) {
  .message {
    padding-left: calc(var(--icon-size) + 48px);
  }

  .message::before {
    left: 32px;
  }
}

/* display for Safari <13 only */
@supports not (display: flow-root) {
  .container {
    display: block;
  }
}

/* display for IE9 only */
@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .container {
    display: block;
  }
}

/* display for IE10 and IE11 only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .container {
    display: block;
  }
}

/* display for pre-chromium Edge only */
@supports (-ms-ime-align: auto) {
  .container {
    display: block;
  }
}
