@import "../../styles/colors.css";
@import "../../styles/layout.css";
@import "../../styles/typography/variables.css";
@import "../../styles/viewports.css";

:root {
  --breadcrumb-height: 50px;
}

.container {
  display: none;
  margin: 0 auto;
  padding: 0;
}

.list {
  display: flex;
  align-items: center;
  height: var(--breadcrumb-height);
  padding: 0 var(--small-screen-side-padding);
}

.container a {
  color: var(--black);
  text-decoration: none;
}

.container ol { 
  white-space: nowrap;
  display: flex;
}

.listItem {
  display: inline-block;
  composes: leavesden3 from "~styles/typography/styles.css";
}

.listItem span {
  display: inline-block;
  padding: 0 11px 0 14px;
}

.listItem:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
}

.listItem:last-child,
.listItem:last-child a {
  color: var(--grey-70);
  margin-right: 5px;
}

@media (--medium-viewport) {
  .container {
    max-width: var(--large-screen-max-content-width);
    display: block;
  }

  .container:not(:empty) {
    height: var(--breadcrumb-height);
  }

  .list {
    padding: 0 var(--medium-screen-side-padding);
  }
}

@media (--large-viewport) {
  .list {
    padding: 0 var(--large-screen-side-padding);
  }
}
