@import "../../../styles/colors.css";

.paymentOptions {
  margin: 20px 0 20px 50px;
  padding: 0 0 0 50px;
  height: 20px;
  display: inline-flex;
  vertical-align: top;
  border-left: 1px solid var(--warm-grey);
  text-align: left;
  overflow: hidden;
}

.provider:not(:first-child) {
  margin-left: 36px;
}

.provider img{
  height: 20px;
}
