@import "../../../../styles/borders.css";
@import "../../../../styles/colors.css";
@import "../../../../styles/viewports.css";

:root {
  --square-item-image-width-small: 107px;
  --square-item-image-height-small: 80px;
  --square-item-image-width-medium: 134px;
  --square-item-image-height-medium: 100px;
}

.container {
  display: inline-block;
  width: 109px;
  height: 120px;
  margin-bottom: 16px;
  vertical-align: top;
}

.container:nth-child(odd) {
  margin-right: 16px;
}

.container:nth-last-child(2) {
  margin-bottom: 0;
}

.container:last-child {
  margin-bottom: 0;
}

.link {
  display: block;
  border: 1px solid var(--grey-20);
  box-sizing: border-box;
  width: 109px;
  height: 120px;
  padding: 0;
  background-color: var(--white);
  text-decoration: none;
}

.link:focus {
  outline: none;
  border: var(--blue-border);
}

.image {
  background-position: 0 0;
  background-size: var(--square-item-image-width-small) var(--square-item-image-height-small);
  width: var(--square-item-image-width-small);
  height: var(--square-item-image-height-small);
}

.label {
  composes: london4 from "~styles/typography/styles.css";
  box-sizing: border-box;
  width: 107px;
  height: 38px;
  padding: 0 4px;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
}

.label__sale {
  color: var(--red);
}

@media (--smallish-viewport) {
  .container {
    width: 136px;
    height: 150px;
  }

  .link {
    width: 136px;
    height: 150px;
  }

  .image {
    background-size: var(--square-item-image-width-medium) var(--square-item-image-height-medium);
    width: var(--square-item-image-width-medium);
    height: var(--square-item-image-height-medium);
  }

  .label {
    width: 134px;
    height: 48px;
  }
}
